<template>
  <div
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <h2 class="content-header-title float-left pr-1 mb-0">
      {{ $route.meta.pageTitle }}
    </h2>
    <div class="breadcrumb-wrapper">
      <b-breadcrumb>
        <b-breadcrumb-item to="/">
          <feather-icon
            icon="HomeIcon"
            size="16"
            class="align-text-top"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-for="item in $route.meta.breadcrumb"
          :key="item.text"
          :active="item.active"
          :to="item.to"
        >
          <span v-if="item.active" id="breadcrumb-location">{{ item.text }}</span>
          <span v-else>{{ item.text }}</span>
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
  computed: {
    routeData() {
      return this.$route
    },
  },
}
</script>
