<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Menu Toggle Button -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <div
        class="content-wrapper clearfix col-md-9 col-12"
        :class="contentWidth === 'boxed' ? 'container p-0' : null"
      >
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <div class="content-detached content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <slot />
            </div>
          </div>
        </div>
        <portal-target
          name="content-renderer-sidebar-detached-left"
          slim
        />
      </div>
    </transition>

    <!-- Avatar Menu -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import UserDropdown from './components/UserDropdown.vue'
import AppBreadcrumb from '../AppBreadcrumb.vue'

export default {
  components: {
    BNavbarNav,
    UserDropdown,
    AppBreadcrumb,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>
