<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <!-- !! Avatar Button -->
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ fullName }}
        </p>
        <span class="user-status" />
      </div>
      <b-avatar
        size="40"
        :src="avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <template v-if="isCampaignDefaultModeDropdownShow && userType === 'Internal'">
      <b-dropdown-item
        class="campaign-filter-option"
        @click.capture.native.stop
      >
        <div class="d-flex align-items-center cursor-default mb-1">
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Campaign List Default Mode</span>
        </div>
        <b-form-radio-group
          v-model="campaignFilterSelected"
          class="custom-radio-group"
          :options="campaignFilterOptions"
          stacked
        />
      </b-dropdown-item>

      <b-dropdown-divider />
    </template>

    <!-- !! Theme Button -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="skin = isDark ? 'light' : 'dark'"
    >
      <feather-icon
        size="16"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
        class="mr-50"
      />
      <span>{{ isDark ? 'Light' : 'Dark' }} Theme</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <!-- !! Logout Button -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormRadioGroup,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormRadioGroup,
  },
  data() {
    return {
      campaignFilterSelected: localStorage.getItem('campaign-filter') || 'my-campaign',
      campaignFilterOptions: [
        { text: 'My Campaigns', value: 'my-campaign' },
        { text: 'All Campaigns', value: 'all-campaign' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      userType: 'heroAiAuthentications/getUserType',
    }),
    avatar() {
      return store.getters['heroAiAuthentications/avatar']
    },
    fullName() {
      return store.getters['heroAiAuthentications/fullName']
    },
    isCampaignDefaultModeDropdownShow() {
      return this.$can('Access - My Campaign', 'Campaigns') && this.$can('Access - All Campaign', 'Campaigns')
    },
  },
  watch: {
    campaignFilterSelected(newValue) {
      localStorage.setItem('campaign-filter', newValue)
    },
  },
  created() {
    if (!localStorage.getItem('campaign-filter')) {
      localStorage.setItem('campaign-filter', 'my-campaign')
    }
  },
  methods: {
    logout() {
      this.$router.push({ name: 'heroai-logout' })
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-user {
  ::v-deep .dropdown-menu {
    min-width: 12rem;
    width: unset !important;
  }
  .campaign-filter-option {
    ::v-deep .dropdown-item {
      &:hover {
        background-color: white;
        color: unset;
      }
    }
    .custom-radio-group {
      ::v-deep .custom-radio + .custom-radio {
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
